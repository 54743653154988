import React from "react"
import Layout from "../components/layout"
// import { Link } from "gatsby"
import layoutStyle from '../components/layout.module.scss'
const ContactPage = () => {

    return (
        <Layout page='Contact'>
            <h1>Contact</h1>   
            <div style={{marginTop:40}}>The best way to contact me is via my LinkedIn or ClearanceJobs.</div>
            <a style={{marginTop:40}}href="https://linkedin.com/in/kevan-mordan">linkedin.com/in/kevan-mordan</a>  
            <a style={{marginTop:16}} href="https://clearancejobs.com">clearanceojobs.com</a>  
        </Layout>
    )

}

export default ContactPage